// External
import { NextRouter } from "next/router";
import dayjs from "dayjs";

// components
import AvatarPlaceholder from "@/components/avatarPlaceholder";
import Tag from "@/components/tag";
import Icon from "@/components/icons";
import Input from "@/components/input";
import {
  openOrderRequisition,
  openOrderResults
} from "@/components/labOrdering/utils";
import PhraseOperations from "@/components/phraseLibrary/phraseOperations";
import {
  convertUtcIntToLocalDatetime,
  convertUtcIntToLocalDate
} from "@/components/scheduling/calendars/utils";
import ContentRenderer from "@/components/textArea/contentRenderer";
import { Weight } from "@/components/input/weightInput";
import { FaFolder } from "react-icons/fa";

// store
import { LabOrderInfo } from "@/store/services/encounter";
import { PhraseListItem } from "@/store/services/generated/phrase";
import { PerformingSite } from "@/store/services/patient";

// constants
import {
  METRICS,
  STATUS_KEYS,
  METRIC_LABELS,
  APPOINTMENT_STATUS_TYPES,
  CLAIM_STATUS_TYPES,
  TASK_STATUS_TYPES,
  CLAIM_PROVIDER_LABEL_OVERRIDES,
  CLAIM_PROVIDER_STATUS_TYPES_OVERRIDES
} from "@/globals/constants";
import { DataTag, metrics } from "@/components/datagrid";
import {
  calculateAge,
  getGestationalAge,
  getPostPartumDuration,
  normalizePhoneNumber
} from "@/globals/helpers";
import { parseParioDate } from "@/utils/api/time";

// styles
import styles from "./styles.module.scss";

// get metrics
const {
  DOB,
  DATE,
  DATE_OF_SERVICE,
  APPOINTMENT_DATE,
  EDD,
  PED,
  NAME,
  APPOINTMENT_STATUS,
  APPOINTMENT_TYPE,
  NOTE,
  LINK,
  STARTS,
  ENCOUNTER_TYPE,
  UPLOAD_TIME,
  SENT_FOR_REVIEW_AT,
  REVIEWED_AT,
  RECEIVED_AT,
  DATE_SENT,
  DATE_OF_ENTRY,
  DATE_FULFILLED,
  DATE_UPDATED,
  PHRASE_LAST_EDIT_TIME
} = METRICS;

export function GetColSpan(metric: string): number {
  switch (metric) {
    case METRICS.ORDERS_PERFORMING_SITE:
      return 2;
    case METRICS.LAB_NOTE:
      return 3;
    default:
      return 1;
  }
}

/**
 * Handle formatting metrics data for display in datagrid.
 *
 * @param {Object.<string, string|number|Date|null|{}>} metrics - The metrics data.
 * @param {string} metric - The metric being formatted.
 * @param {string} id - The ID of the metric.
 * @param {function} onToggle - The function to toggle expanded note metric visibility.
 * @param {string[]} openRowIds - An array containg the IDs of rows which are expanded.
 * @param {NextRouter} router - The Next.js router object.
 * @param {DataTag[]} tags - (OPTIONAL) the data tags to render with this metric
 * @returns {JSX.Element|string} The formatted metric data.
 */

export function HandleFormat(
  metrics: metrics,
  metric: string,
  id: number | string,
  onToggle: (id: number) => void,
  openRowIds: number[],
  router: NextRouter,
  tags: DataTag[] = [],
  useOverride?: boolean
): JSX.Element | string | number {
  //const [toggleReviewed, toggleReviewedReq] = useLabToggleOrderReviewedMutation();

  const datum = metrics[metric];

  if ([DATE, APPOINTMENT_DATE, PED].includes(metric)) {
    // Convert & format date metrics
    return datum
      ? convertUtcIntToLocalDate(datum as number).format("MM/DD/YYYY")
      : "-";
  } else if ([DATE_OF_ENTRY].includes(metric)) {
    // Convert & format date metrics
    return datum ? dayjs(datum as string).format("MM/DD/YYYY") : "-";
  } else if (
    [
      SENT_FOR_REVIEW_AT,
      RECEIVED_AT,
      REVIEWED_AT,
      DATE_SENT,
      DATE_FULFILLED,
      DATE_UPDATED
    ].includes(metric)
  ) {
    return datum ? (
      <div>
        <p>{dayjs(datum as string).format("MM/DD/YYYY")}</p>
        <p className="t5">{dayjs(datum as string).format("h:mm A")}</p>
      </div>
    ) : (
      "-"
    );
  } else if (metric == METRICS.RECIPIENT || metric == METRICS.SENDER) {
    const phone = normalizePhoneNumber(datum as string);
    if (phone.error && phone.error !== "") {
      return "";
    } else {
      return phone.normalizedPhoneNumber as string;
    }
  } else if (metric === EDD) {
    const edd = dayjs.utc(datum as string);
    return datum ? (
      <div>
        <p>{edd.format("MM/DD/YYYY")}</p>
        <p className="t5">
          {getGestationalAge(parseInt(edd.format("YYYYMMDD")))}
        </p>
      </div>
    ) : (
      "-"
    );
  } else if (metric === METRICS.PREGNANCY_DELIVERY_DATE) {
    const dt = parseParioDate(datum as number);
    return dt ? (
      <div>
        <p>{dayjs(dt).format("MM/DD/YYYY")}</p>
        <p className="t5">{getPostPartumDuration(datum as number)}</p>
      </div>
    ) : (
      "-"
    );
  } else if (metric === DOB) {
    const dt = parseParioDate(datum as number);
    return dt
      ? dayjs(dt).format("MM/DD/YYYY") + " (Age " + calculateAge(dt) + ")"
      : "-";
  } else if (metric === APPOINTMENT_TYPE) {
    return (
      <Tag
        label={datum as string}
        // @ts-ignore
        type={STATUS_KEYS.INFO}
      />
    );
  } else if (metric === DATE_OF_SERVICE) {
    return dayjs(datum as string).format("MM/DD/YYYY");
  } else if (metric === NAME) {
    // Format name metric with avatar
    const [lastName, firstName] = datum.toString().split(", ");
    return (
      <div className={styles.name}>
        <AvatarPlaceholder character={firstName[0] + lastName[0]} />
        <div>
          {lastName}, {firstName}
        </div>
        {tags.map((tag, index) => (
          <Tag label={tag.label} type={tag.type} key={index} />
        ))}
      </div>
    );
  } else if (metric === ENCOUNTER_TYPE) {
    return METRIC_LABELS[datum as string] || (datum as string);
  } else if (metric === APPOINTMENT_STATUS) {
    // Format appointment type metric with tag
    // TODO: replace string with constant after constant changes merged in
    return (
      <Tag
        label={METRIC_LABELS[datum as string] as string}
        // @ts-ignore
        type={APPOINTMENT_STATUS_TYPES[datum as string]}
      />
    );
  } else if (metric === METRICS.STATUS) {
    const label = useOverride
      ? CLAIM_PROVIDER_LABEL_OVERRIDES[datum as string] ||
        (METRIC_LABELS[datum as string] as string) ||
        (datum as string)
      : (METRIC_LABELS[datum as string] as string) || (datum as string);

    const type = useOverride
      ? CLAIM_PROVIDER_STATUS_TYPES_OVERRIDES[datum as string] ||
        CLAIM_STATUS_TYPES[datum as string] ||
        TASK_STATUS_TYPES[datum as keyof typeof TASK_STATUS_TYPES] ||
        STATUS_KEYS.INFO
      : CLAIM_STATUS_TYPES[datum as string] ||
        TASK_STATUS_TYPES[datum as keyof typeof TASK_STATUS_TYPES] ||
        STATUS_KEYS.INFO;

    return <Tag label={label} type={type} />;
  } else if (metric === NOTE) {
    // Format note metric with truncation and toggle button
    return (
      <div className={styles.note}>
        {/* cut off at the first five letters */}
        <button onClick={() => onToggle(id as number)} type="button">
          <Icon
            svg="chevron_down_blue"
            width={10}
            flipped={openRowIds.includes(id as number)}
          />
        </button>
      </div>
    );
  } else if (metric === LINK) {
    // Format link metric with button and router navigation
    return (
      <div className={styles.actions}>
        <button onClick={() => router.push(datum as string)}>
          <Icon svg="open_page" />
        </button>
      </div>
    );
  } else if (metric === STARTS) {
    return (
      datum && convertUtcIntToLocalDatetime(datum as number).format("h:mm a")
    );
  } else if (
    (metric === METRICS.STATUS &&
      typeof METRICS.STATUS === "string" &&
      (datum as string).toUpperCase() === "INCOMPLETE") ||
    (typeof datum === "string" && datum.toUpperCase() === "PENDING")
  ) {
    return <Tag label={datum as string} type={STATUS_KEYS.WARNING} />;
  } else if (
    metric === METRICS.STATUS &&
    (datum as string).toUpperCase() === "COMPLETE"
  ) {
    return <Tag label={datum as string} type={STATUS_KEYS.SUCCESS} />;
  } else if (
    metric === METRICS.STATUS &&
    (datum as string).toUpperCase() === "OVERDUE"
  ) {
    return <Tag label={datum as string} type={STATUS_KEYS.ERROR} />;
  } else if (metric === METRICS.FILE_VISIBLE_TO_PATIENT) {
    return (
      <Tag
        label={datum as string}
        type={datum === "Yes" ? STATUS_KEYS.WARNING : STATUS_KEYS.INFO}
      />
    );
  } else if (metric === METRICS.TASK_ACTIONS) {
    return (
      <>
        <Icon svg="bell" />
        <span>
          <Icon svg="check_circle_blue" />
        </span>
      </>
    );
  } else if (metric === METRICS.NEW_TAB_LINK) {
    return datum ? (
      <div className={styles.actions}>
        <button onClick={() => window.open(datum as string, "_blank")}>
          <Icon svg="open_page" />
        </button>
      </div>
    ) : (
      "-"
    );
  } else if (metric === METRICS.ORDER_REQUISITION_LINK) {
    return datum ? (
      <div className={styles.actions}>
        <button onClick={() => openOrderRequisition(datum as string)}>
          <Icon svg="open_page" />
        </button>
      </div>
    ) : (
      "-"
    );
  } else if (metric === METRICS.ORDER_RESULT_NOTE) {
    // We don't have a lot of guarantees about the format of the note, since it
    // can come from one of many possible labs. So we'll do our best to format
    // it in a clear, but generic way.
    const lines = (datum as string)
      .split(/(\s)/) // split the note along spaces, tabs, and line breaks
      .filter(x => x != " " && x != "") // remove empty space
      .join(" ") // join the not eback together so we have one long string
      .split("\n") // split the note along line breaks
      .map(x => x.trim()) // remove extra whitespace from the beginning and end of each line
      .filter(x => x); // remove empty lines

    return (
      <div data-cy="order-note-from-lab">
        {lines.reduce<JSX.Element[]>((acc, line, i, arr) => {
          acc.push(<p>{line}</p>);
          if (i < arr.length - 1) {
            acc.push(<br />);
          }

          return acc;
        }, [])}
      </div>
    );
  } else if (metric === METRICS.ORDER_RESULTS_LINK) {
    return datum ? (
      <div className={styles.actions}>
        <button onClick={() => openOrderResults(datum as string)}>
          <Icon svg="open_page" />
        </button>
      </div>
    ) : (
      "-"
    );
  } else if (metric === METRICS.ENCOUNTER_LINK) {
    return (
      <div className={styles.actions}>
        <button onClick={() => router.push(datum as string)}>
          <Icon svg="encounter" />
        </button>
      </div>
    );
  } else if (metric === METRICS.ORDER_REVIEWED) {
    const order = datum as LabOrderInfo;
    return (
      <Tag
        label={order.reviewed ? "Reviewed" : "Not Reviewed"}
        type={order.reviewed ? STATUS_KEYS.SUCCESS : STATUS_KEYS.WARNING}
        customIconSvgPath={
          order.reviewed ? "check_success_outline" : "x_failure_outline"
        }
      />
    );
  } else if (metric === METRICS.ORDERS_PERFORMING_SITE) {
    const site = datum as PerformingSite | undefined;
    return !site ? (
      "-"
    ) : (
      <div>
        <p className="med">{site.contact}</p>
        <p className="t5">{site.address?.line1}</p>
        <p className="t5">
          {site.address?.city}, {site.address?.state} {site.address?.zip}
        </p>
        <p className="t5">{site.name}</p>
      </div>
    );
  } else if (metric === METRICS.TIME) {
    return convertUtcIntToLocalDatetime(datum as number).format("hh:mm a");
  } else if (metric === METRICS.ORDER_STATUS) {
    const status = (datum as string).replaceAll("_", "").trim();
    // capitalize the first letter of the status, lower case the rest
    const label =
      status?.charAt(0)?.toUpperCase() + status?.slice(1)?.toLowerCase();

    return (
      <div className={styles.actions}>
        <Tag
          label={label}
          type={
            {
              new: STATUS_KEYS.INFO_GREY,
              sent: STATUS_KEYS.INFO,
              in_progress: STATUS_KEYS.INFO,
              preliminary: STATUS_KEYS.INFO,
              fulfilled: STATUS_KEYS.WARNING,
              reviewed: STATUS_KEYS.SUCCESS,
              error: STATUS_KEYS.ERROR,
              normal: STATUS_KEYS.SUCCESS,
              final: STATUS_KEYS.SUCCESS
            }[status.toLowerCase().trim()] || STATUS_KEYS.ERROR
          }
        />
      </div>
    );
  } else if (metric === UPLOAD_TIME || metric === PHRASE_LAST_EDIT_TIME) {
    return datum ? (
      <div>
        <p>{convertUtcIntToLocalDate(datum as number).format("MM/DD/YYYY")}</p>
        <p className="t5">
          {convertUtcIntToLocalDatetime(datum as number).format("hh:mm A")}
        </p>
      </div>
    ) : (
      "-"
    );
  } else if (metric === METRICS.FILENAME) {
    const isDir = (datum as string)?.endsWith("/");
    return (
      <div className="flex" style={{ whiteSpace: "nowrap" }}>
        {isDir ? (
          <FaFolder />
        ) : (
          <Icon svg="file_highlighted" width={30} height={30} />
        )}
        <span
          style={{
            marginLeft: 2,
            overflow: "hidden",
            textOverflow: "ellipsis"
          }}
        >
          {datum as string}
        </span>
      </div>
    );
  } else if (metric === METRICS.FILE_TAGS) {
    if (!datum) return "-";

    const tags = (datum as string).split(",").map(tag => tag.trim());
    return (
      <div
        style={{
          maxWidth: "100%",
          display: "flex",
          flexWrap: "wrap",
          gap: "10px"
        }}
      >
        {tags.map(tag => (
          <Tag key={tag} label={tag} type={STATUS_KEYS.INFO} />
        ))}
      </div>
    );
  } else if (metric === METRICS.PHRASE_OPERATIONS) {
    return <PhraseOperations phrase={datum as PhraseListItem} />;
  } else if (metric === METRICS.OUTBOUND_FAX_STATUS) {
    // "success" is the known value Documo returns for when a fax is returned, the other statuses
    // we're unsure about so everything else is of info status
    const statusKey =
      datum === "success" ? STATUS_KEYS.SUCCESS : STATUS_KEYS.INFO_GREY;
    const tagLabel =
      (datum as string).charAt(0).toUpperCase() +
      (datum as string).slice(1).toLowerCase();
    // I do a Date.valueOf() to add a random number to the key because there may be multiple tags
    // with the same tagLabel but it's not critical that it's unique so Date.valueOf is good enough
    return (
      <Tag
        key={`${Date.valueOf()}-${tagLabel}`}
        label={tagLabel}
        type={statusKey}
      />
    );
  } else if (metric === METRICS.DATA_TAGS) {
    return <Tag label={datum as string} type={STATUS_KEYS.WARNING} />;
  } else if (metric === METRICS.CLAIM_ID || metric === METRICS.ORDER_ID) {
    return (
      <button
        type="button"
        onClick={() => {
          navigator.clipboard.writeText(datum as string);
          alert("Copied full ID " + datum + " to clipboard");
        }}
        title={"Click to copy full ID to clipboard"}
      >
        <Tag label={(datum as string).slice(-5)} type={STATUS_KEYS.INFO_GREY} />
      </button>
    );
  } else if (metric === METRICS.CREATED_AT) {
    return datum ? dayjs(datum as string).format("MM/DD/YYYY") : "-";
  } else if (metric === METRICS.LAB_NOTE) {
    return (
      <div className={styles.labNote}>
        {datum &&
          (datum as string).split("\n").map((line, idx) => (
            <p
              key={`line-${idx}`}
              dangerouslySetInnerHTML={{
                __html: line.replaceAll(" ", "&nbsp;")
              }}
            ></p>
          ))}
      </div>
    );
  } else if (metric === METRICS.REASON) {
    return <ContentRenderer content={datum as string} />;
  } else if (metric === METRICS.SIGNUP_COMPLETED) {
    return datum ? (
      <div title="Signup completed">
        <Icon svg="badge_check" width={14} height={14} />
      </div>
    ) : (
      "-"
    );
  } else if (metric === METRICS.DUE_DATE) {
    return datum ? dayjs(datum as string).format("MM/DD/YYYY") : "-";
  } else if (metric === METRICS.BIRTH_WEIGHT) {
    const weight = datum as Weight;
    return (
      <div>
        <p>{`${weight.pounds || 0} lbs ${weight.ounces || 0} oz`}</p>
        <p className="t5">{(weight.grams || 0).toFixed(2)} grams</p>
      </div>
    );
  } else if (metric === METRICS.INCOMPLETE_TASK_COUNT) {
    return datum ? (
      <Tag label={datum as string} type={STATUS_KEYS.WARNING} />
    ) : (
      <Tag label="0" type={STATUS_KEYS.SUCCESS} />
    );
  } else {
    if (datum && typeof datum === "string") {
      return METRIC_LABELS[datum] || datum;
    }
    if (!datum || Number.isNaN(datum))
      // Return other metrics as string
      return "-";

    return datum as string;
  }
}
