/* EncounterTimeEdit Name */
/* External Imports */
import clsx from "clsx";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

/* Local Imports */

// components
import Input from "@/components/input";
import Button from "@/components/button";

// constants
import { STATUS_KEYS, STYLES } from "@/globals/constants";

// store
import {
  EncounterCreateUpdatePayload,
  EncounterInfo,
  EncounterType,
  useEncounterUpdateMutation
} from "@/store/services/encounter";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { setModalIsOpen } from "../modalSlice";
// utils
import {
  convertLocalDatetimeToUtcInt,
  convertUtcIntToLocalDatetime
} from "@/components/scheduling/calendars/utils";

// styles
import styles from "../styles.module.scss";
import ControlledCombobox from "@/components/input/controlledCombobox";
import {
  ProviderListItem,
  usePracticeGetProvidersQuery
} from "@/store/services/practice";
import { RootState } from "@/store/store";
import Skeleton from "react-loading-skeleton";
import { FORMAT } from "@/globals/helpers/formatters";
import { ProviderInfo } from "@/store/services/patient";
import { useMemo } from "react";
import {
  SelectDefaultStyles,
  SelectDefaultTheme
} from "@/styles/themes/selectDefaultTheme";

/* EncounterDetails Typescript Interface */
interface EditEncounterDetailsProps {
  encounter: EncounterInfo;
}

export default function EditEncounterDetails({
  encounter
}: EditEncounterDetailsProps) {
  const { sessionInfo } = useSelector((state: RootState) => state.auth);

  const { handleSubmit, register, setValue, control, getValues } = useForm({
    defaultValues: {
      start_time:
        encounter.start || encounter.appointment?.starts || encounter.created
          ? convertUtcIntToLocalDatetime(
              encounter.start ||
                encounter.appointment?.starts ||
                encounter.created
            ).format("HH:mm")
          : "",
      encounter_date:
        encounter.start || encounter.appointment?.starts || encounter.created
          ? convertUtcIntToLocalDatetime(
              encounter.start ||
                encounter.appointment?.starts ||
                encounter.created
            ).format("YYYY-MM-DD")
          : "",
      end_time:
        encounter.end || encounter.appointment?.ends
          ? convertUtcIntToLocalDatetime(
              encounter.end || (encounter.appointment?.ends as number)
            ).format("HH:mm")
          : "",
      provider: encounter.provider
    }
  });
  /* Redux */
  const [updateEncounter] = useEncounterUpdateMutation();

  const { data: providers } = usePracticeGetProvidersQuery(
    { practiceId: sessionInfo?.practice_id as number },
    { skip: !sessionInfo?.practice_id }
  );

  type ProviderOption = {
    label: string;
    value: ProviderListItem;
  };

  const providerOptions: ProviderOption[] = useMemo(() => {
    return (providers || []).map(provider => ({
      label: FORMAT.name(provider),
      value: provider
    }));
  }, [providers]);

  const dispatch = useDispatch();

  /* Event Handlers */
  const onSubmit = (data: any) => {
    const { start_time, end_time, encounter_date } = data;
    const startTime = dayjs(`${encounter_date} ${start_time}`);
    const startTimestamp = convertLocalDatetimeToUtcInt(startTime);
    const encounterUpdatePayload: EncounterCreateUpdatePayload = {
      encounter_type: encounter.encounter_type as EncounterType,
      patient_id: encounter.patient.user_id,
      provider_id: data.provider
        ? data.provider.user_id
        : encounter.provider.user_id,
      start: startTimestamp
    };
    if (end_time) {
      const endTime = dayjs(`${encounter_date} ${end_time}`);
      const endTimestamp = convertLocalDatetimeToUtcInt(endTime);
      encounterUpdatePayload.end = endTimestamp;
    }
    updateEncounter({
      encounterId: encounter.encounter_id,
      encounterCreateUpdatePayload: encounterUpdatePayload
    })
      .unwrap()
      .then(r => {
        dispatch(
          addAlertToToastTrough({
            message: "Encounter details successfully updated",
            type: STATUS_KEYS.SUCCESS
          })
        );
        dispatch(setModalIsOpen(false));
      })
      .catch(() =>
        dispatch(
          addAlertToToastTrough({
            message: "Failed to update encounter details",
            type: STATUS_KEYS.ERROR
          })
        )
      );
  };
  return (
    <form
      className={clsx(styles.EncounterEdit)}
      onSubmit={handleSubmit(onSubmit)}
    >
      {providers && providerOptions.length > 0 ? (
        <Controller
          control={control}
          name="provider"
          render={({ field: { onChange, value } }) => (
            <Select
              options={providerOptions}
              id="provider-select"
              onChange={(newValue: any) => {
                onChange(newValue.value);
              }}
              value={providerOptions.find(
                option => option.value.user_id === value.user_id
              )}
              styles={SelectDefaultStyles}
              theme={SelectDefaultTheme}
            />
          )}
        />
      ) : (
        <Skeleton height={24} />
      )}
      <Input
        type="date"
        label="Encounter Date"
        name="encounter_date"
        id="encounter_date"
        fullWidth
        register={register}
      />
      <div className={styles.timeContainer}>
        <Input
          type="time"
          label="Start time"
          name="start_time"
          id="start_time"
          register={register}
        />
        <Input
          type="time"
          label="End time"
          name="end_time"
          id="end_time"
          register={register}
        />
      </div>
      <Button style={STYLES.FULL_WIDTH} type="submit">
        Save
      </Button>
    </form>
  );
}
