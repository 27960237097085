import FacePage from "@/components/facePage";
import Appointment from "../modal/templates/appointment";
import ViewFile from "../modal/templates/viewFile";
import GenericFormModal from "../modal/templates/genericForm";
import ViewInteractiveFile from "../modal/templates/viewInteractiveFile";
import ViewClaim from "../modal/templates/viewClaim";
import DemographicsTaskModal from "../modal/templates/demographicsTaskModal";
import UploadTaskModal from "../modal/templates/uploadTask";
import GenericTaskModal from "../modal/templates/genericTask";
import AddNewPayorModal from "../modal/templates/addNewPayor";
import PatientRecallEdit from "../modal/templates/patientRecallEdit";

import { MODAL_TYPES } from "../modal/dispatcher";
import LabFlowsheetEntry from "../modal/templates/labFlowsheetEntry";
import LabResults from "../modal/templates/labResults/labResults";

const DRAWER_TYPES = {
  FACEPAGE: "face_page",
  HH: "health_history",
  PE: "physical_exam",
  LOADING: "loading",
  VIEW_CLAIM: "view_claim",
  LAB_FLOWSHEET_ENTRY: "lab_flowsheet_entry",
  LAB_RESULTS: "lab_results"
};

const DRAWER_DISPATCHER = {
  [DRAWER_TYPES.FACEPAGE]: (props: any) => <FacePage {...props} />,
  [MODAL_TYPES.APPOINTMENT]: (props: any) => <Appointment {...props} />,
  [MODAL_TYPES.VIEW_FILE]: (props: any) => <ViewFile {...props} />,
  [MODAL_TYPES.GENERIC_FORM]: (props: any) => <GenericFormModal {...props} />,
  [MODAL_TYPES.VIEW_INTERACTIVE_FILE]: (props: any) => (
    <ViewInteractiveFile {...props} />
  ),
  [DRAWER_TYPES.VIEW_CLAIM]: (props: any) => <ViewClaim {...props} />,
  [MODAL_TYPES.UPLOAD_TASK]: (props: any) => <UploadTaskModal {...props} />,
  [MODAL_TYPES.GENERIC_TASK]: (props: any) => <GenericTaskModal {...props} />,
  [MODAL_TYPES.DEMOGRAPHICS_TASK]: (props: any) => (
    <DemographicsTaskModal {...props} />
  ),
  [MODAL_TYPES.ADD_NEW_PAYOR]: (props: any) => <AddNewPayorModal {...props} />,
  [MODAL_TYPES.PATIENT_RECALL_EDIT]: (props: any) => (
    <PatientRecallEdit {...props} />
  ),
  [DRAWER_TYPES.LAB_FLOWSHEET_ENTRY]: (props: any) => (
    <LabFlowsheetEntry {...props} />
  ),
  [DRAWER_TYPES.LAB_RESULTS]: (props: any) => <LabResults {...props} />
};

export { DRAWER_DISPATCHER, DRAWER_TYPES };
