/* Share Records Modal Template */
// External
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import Skeleton from "react-loading-skeleton";
import libphonenumber from "google-libphonenumber";

// components
import Input from "@/components/input";
import Button from "@/components/button";
import AdditionalDataCheckList, {
  AdditionalDataFormType
} from "@/components/forms/_labor/transferDecisionForm/additionalDataCheckList";

// Globals, helpers, constants
import {
  STATUS_KEYS,
  STYLES
} from "@/globals/constants";

// store
import { RootState } from "@/store/store";
import { setModalContent, setModalIsOpen } from "../modalSlice";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { FORMAT } from "@/globals/helpers/formatters";
import {
  ErrorInfo,
  usePatientGetInfoQuery,
  usePatientGeneratePdfMutation,
  PatientGeneratePdfPayload,
  PatientPdfSection
} from "@/store/services/patient";
import { usePracticeInfoQuery } from "@/store/services/practice";
import { useProviderGetInfoQuery } from "@/store/services/provider";

// styles
import styles from "../styles.module.scss";
import FaxContact from "@/components/input/faxContact";
import ControlledTextArea from "@/components/textArea/controlledTextArea";
import { getBlobFromBase64String } from "@/globals/helpers/fileHelpers";
import { MODAL_TYPES } from "../dispatcher";
import { useFaxSendMutation } from "@/store/services/fax";
import { useFileUploadMutation } from "@/store/services/file";
import dayjs from "dayjs";

export interface NewMessageModalProps {
  patientId: number;
}

interface FormValues extends AdditionalDataFormType {
  recipient_name: string;
  custom_recipient: string;  // Add this for fax number
  provider_name: string;
  provider_phone: string;
  transferReason?: string;
}

export default function ShareRecordsModal({ patientId }: NewMessageModalProps) {
  const dispatch = useDispatch();
  const { sessionInfo } = useSelector((state: RootState) => state.auth);

  const [encounterList, setEncounterList] = useState<number[]>([]);
  const [_, setTransferReasonContent] = useState<string>("");

  const { data: patient } = usePatientGetInfoQuery(
    { patientId },
    { skip: !patientId }
  );
  const { data: provider } = useProviderGetInfoQuery(
    { providerId: sessionInfo?.user_id as number },
    { skip: !sessionInfo }
  );
  const { data: practice } = usePracticeInfoQuery(
    { practiceId: sessionInfo?.practice_id as number },
    { skip: !sessionInfo || !sessionInfo.practice_id }
  );

  const [generatePdf, { isLoading }] = usePatientGeneratePdfMutation();
  const [sendFax, sendFaxResponse] = useFaxSendMutation();
  const [uploadFile] = useFileUploadMutation();

 function handleErrorToast(error: ErrorInfo, fallback: string) {
    if (error?.user_facing) {
        dispatch(addAlertToToastTrough({
            message: error.message,
            type: STATUS_KEYS.ERROR
        }));

        for (const e of (error?.extra_data || [])) {
            dispatch(
                addAlertToToastTrough({
                    message: e.message,
                    type: STATUS_KEYS.ERROR
                })
            );
        }
    } else {
        dispatch(addAlertToToastTrough({
            message: fallback,
            type: STATUS_KEYS.ERROR
        }));
    }
}

  const WATCH_KEYS = [
    // Forms to add to PDF
    "demographics",
    "insurance",
    "problem_list",
    "current_meds",
    "allergies",
    "health_history",
    "prenatal_labs",
    "prenatal_flow_sheet",
    "encounters",
    "pediatric_record",
    // User inputed data for cover sheet and fax
    "recipient_name",
    "transfer_reasons",
    "other_reasons",
    "provider_name",
    "transport_from",
    "provider_phone"
  ];
  const form = useForm<FormValues>({
    defaultValues: {
      provider_phone: practice?.phone || provider?.phone,
      custom_recipient: '',
    }
  });
  const {
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors }
  } = form;

  const watches: Record<string, any> = {};

  WATCH_KEYS.forEach(key => {
    watches[key] = watch(key);
  });

  const transferReason = watch("transferReason");

  useEffect(() => {
    if (provider) {
      setValue("provider_name", FORMAT.name(provider));
      let phoneStr = practice?.phone || provider?.phone;
      if (phoneStr) {
        const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
        const phone = phoneUtil?.parse(phoneStr, "US");
        phoneStr = phoneUtil.formatInOriginalFormat(phone, "US") || "";
        setValue("provider_phone", phoneStr);
      }
    }
  }, [provider, practice]);

  // Effect for watching the encounter
  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (!name?.startsWith("encounter-")) {
        return;
      }
      let attachEncounters: number[] = [];
      Object.keys(value).forEach(key => {
        if (!key.startsWith("encounter-") || value[key] === false) {
          return;
        }
        const encounterId = parseInt(key.slice(10));
        attachEncounters.push(encounterId);
      });
      setEncounterList(attachEncounters);
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (sendFaxResponse.isError) {
      dispatch(
        addAlertToToastTrough({
          message: "Failed to share records",
          type: STATUS_KEYS.ERROR as keyof typeof STATUS_KEYS
        })
      );
    }
  }, [sendFaxResponse]);

  const createTransferPdf = async (data: any) => {
    setTransferReasonContent(transferReason || "");
    const fileKeys = Object.keys(data).filter(
      key => data[key]
      && (
        /^file_/.test(key)
        || /^lab_/.test(key)
        || /^imaging_/.test(key)
      )
    );
    const fileIds = fileKeys.map(key => parseInt(key.replaceAll(/^[^_]*_/g, "")));

    const orderKeys: string[] = [];
    Object.keys(data).forEach(key => {
      if (data[key] && /^lab_order_/.test(key)) {
        orderKeys.push(key.slice(4));
      }
    });

    let sections: PatientPdfSection[] = [];
    for (const [key, value] of Object.entries(data)) {
      if ((key == "demographics"
        || key == "insurance"
        || key == "problem_list"
        || key == "medication_list"
        || key == "allergy_list"
        || key == "health_history"
        || key == "prenatal_flowsheet"
        || key == "labor_sheet"
        || key == "newborn_exam"
        || key == "pregnancy_edd"
        || key == "pediatric_record"
        || key == "past_pregnancies") && value === true) {
        sections.push(key);
      }
    }

    const payload: PatientGeneratePdfPayload = {
      sections,
      extra_file_ids: fileIds,
      coverpage: {
        to_name: data.recipient_name,
        to_fax: data.custom_recipient,
        subject: "Record Transfer",
        comment: data.transferReason,
      },
    }

    if (encounterList.length > 0) {
      payload.sections.push("encounter_list");
      payload.encounter_list_params = {
        encounter_ids: encounterList
      };
    }

    const previewPdfBase64 = await generatePdf({
      patientId: patient?.user_id as number,
      patientGeneratePdfPayload: payload
    }).unwrap().catch(err => {
      handleErrorToast(err, "Failed to generate transfer preview");
    })

    if (!previewPdfBase64) {
      return;
    }

    dispatch(setModalIsOpen(true));
    dispatch(setModalContent({
      type: MODAL_TYPES.PREVIEW_TRANSFER,
      props: {
        title: "Transfer",
        url: URL.createObjectURL(getBlobFromBase64String(previewPdfBase64.content)),
        onSubmit: async () => {
          if (!data.custom_recipient) {
            dispatch(addAlertToToastTrough({
              message: "Recipient can't be blank",
              type: STATUS_KEYS.ERROR
            }));
            return;
          }

          // Save to patient files and send fax
          const name = "Record Transfer " + dayjs().format("YYYY-MM-DD HH.mm.ss") + ".pdf";
          const content = getBlobFromBase64String(previewPdfBase64.content)
          const file = new File([content], name);
          const formData = new FormData();
          formData.append(file.name, file);
          uploadFile({
            patientId: patient?.user_id as number,
            body: formData
          }).unwrap().then(created => {
            dispatch(
              addAlertToToastTrough({
                message: "PDF saved to patient files",
                type: STATUS_KEYS.SUCCESS
              })
            );

            const file_id = Object.values(created)[0];
            sendFax({
              faxSendRequest: {
                recipients: [data.custom_recipient],
                files: [file_id]
              },
            }).then(() => {
              dispatch(
                addAlertToToastTrough({
                  message: "Fax sent",
                  type: STATUS_KEYS.SUCCESS
                })
              );

              dispatch(setModalIsOpen(false));
            }).catch((err: ErrorInfo) => {
              handleErrorToast(err, "Failed to send fax");
            });
          }).catch((err: ErrorInfo) => {
            handleErrorToast(err, "Failed to save PDF to patient files");
          });
        }
      }
    }));
  };

  if (!patient) {
    return <p>Loading...</p>;
  }

  if (sendFaxResponse.isLoading) {
    return (
      <div className={styles.ShareRecordsModal}>
        <Skeleton height={40} />
        <br />
        <Skeleton height={40} />
        <br />
        <Skeleton height={40} />
        <br />
        <Skeleton height={40} />
      </div>
    );
  }

  return (
    <div className={styles.ShareRecordsModal}>
      <form onSubmit={handleSubmit(createTransferPdf)}>
        <Input
          type="text"
          label="Recipient Name*"
          name="recipient_name"
          id="recipient_name"
          register={register}
          fullWidth
          rules={{
            required: true
          }}
          error={errors.recipient_name && "Recipient can't be blank"}
        />
        <input
          type="hidden"
          {...register('custom_recipient', {
            required: "Recipient fax can't be blank"
          })}
        />
        <FaxContact
          label="Recipient Fax*"
          required
          onChange={item => {
            if (item) {
              setValue("custom_recipient", item.fax, {
                shouldValidate: true,
              })
            }
          }}
          error={errors.custom_recipient && "Recipient fax can't be blank"}
        />
        <Input
          label="Sender Name*"
          name="provider_name"
          id="provider_name"
          type="text"
          fullWidth
          placeholder="Provider's name"
          register={register}
          rules={{ required: true }}
          error={
            errors.deciding_provider && "Please enter a name for the provider"
          }
        />
        <Input
          label="Sender Phone*"
          name="provider_phone"
          id="provider_phone"
          type="text"
          fullWidth
          placeholder="(888) 555 4444"
          register={register}
          rules={{
            required: true,
            minLength: 10
          }}
          error={
            errors.provider_phone &&
            "Please check the provider phone and try again."
          }
        />
        <div className={styles.fullWidth}>
          <ControlledTextArea
            name="transferReason"
            form={form}
            label="Note"
            id="transferReason"
            rows={4}
          />
        </div>
        <br />
        <p className={styles.fullWidth}>
          <strong>Select what records to share</strong>
        </p>
        {patient && (
          <div className={styles.fullWidth}>
            <AdditionalDataCheckList
              patient={patient}
              // @ts-ignore
              form={form}
              genericRecords
            />
          </div>
        )}
        <div className={styles.fullWidth}>
          <Button style={STYLES.FULL_WIDTH} type="submit" loading={isLoading}>
            Preview & Fax
          </Button>
        </div>
      </form>
    </div>
  );
}
