import { z } from "zod";
import { METRICS, STATE_CODES } from ".";
import { FORMAT } from "../helpers/formatters";

// TEMP: these constants, types and helpers will be moved once we have
// the schema finalized by Kean

// Form field type options
const FIELD_TYPES = {
  TEXTAREA: "TEXTAREA",
  TEXT: "TEXT",
  CHECKBOX: "CHECKBOX",
  PHONE: "PHONE",
  DATE: "DATE",
  DATETIME: "DATETIME",
  SINGLE_DROPDOWN: "SINGLE_DROPDOWN",
  MULTI_DROPDOWN: "MULTI_DROPDOWN",
  STATE: "STATE",
  ZIPCODE: "ZIPCODE",
  BOOL: "BOOL",
  TABLE: "TABLE",
  FIELDSET: "FIELDSET",
  EMAIL: "EMAIL",
  NUMBER: "NUMBER",
  AMOUNT: "AMOUNT",
  TIME: "time",
  NAME: "name",
  TEXT_REQ: "text_required",
  DATE_REQ: "date_required",
  TIME_REQ: "time_required",
  ANY: "any",
  SSN: "ssn"
};

const isNonEmptyString = (value: string) => value.trim().length > 0;
const phoneRegExp = /^(?:\+\d{1,3}[- ]?)?\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/;

// Zod type dictionary corresponding to each field type
const FIELD_VALIDATION = {
  [FIELD_TYPES.TEXT]: z.string().nullable().optional(),
  [FIELD_TYPES.TEXT_REQ]: z.string(),
  [FIELD_TYPES.NAME]: z
    .string()
    .min(3, { message: "Name must contain at least 3 characters" })
    .refine(isNonEmptyString, {
      message: "Field must not be empty or contain only spaces"
    }),
  [FIELD_TYPES.ANY]: z.any(),
  [FIELD_TYPES.EMAIL]: z.string().email().optional().or(z.literal("")),
  [FIELD_TYPES.NUMBER]: z.number(),
  [FIELD_TYPES.CHECKBOX]: z.boolean(),
  [FIELD_TYPES.DATE]: z.string().regex(/^\d{4}-\d{2}-\d{2}$/), // Date in the format "YYYY-MM-DD"
  [FIELD_TYPES.TIME]: z.string().regex(/^\d{2}:\d{2}$/), // Time in the format "HH:mm"
  [FIELD_TYPES.PHONE]: z
    .string()
    .regex(phoneRegExp, { message: "Invalid phone number" })
    .min(5, { message: "Phone must contain at least 5 characters" })
    .max(16, { message: "Phone contain at most 16 characters" })
    .optional()
    .or(z.literal("")),
  [FIELD_TYPES.DATE_REQ]: z.string().regex(/^\d{4}-\d{2}-\d{2}$/), // Date in the format "YYYY-MM-DD"
  [FIELD_TYPES.TIME_REQ]: z.string().regex(/^\d{2}:\d{2}$/), // Time in the format "HH:mm"
  [FIELD_TYPES.SSN]: z.string().nullable().optional() // SSN
};

type FORM_ITEMS = {
  fieldType: keyof typeof FIELD_VALIDATION;
  fieldID: string;
  fieldOptions?: string[];
}[];
//
/* Generate zod object map from form items schema e.g.:
    {
      email: z.string().email(),
      number: z.number()
    }
  */
const buildZodObject = (items: FORM_ITEMS) =>
  items.reduce(
    (accum, { fieldID, fieldType }) => ({
      ...accum,
      [fieldID]: FIELD_VALIDATION[fieldType]
        ? FIELD_VALIDATION[fieldType]
        : FIELD_VALIDATION[FIELD_TYPES.ANY]
    }),
    {}
  );

// mock data
const TestFields = [
  { fieldType: FIELD_TYPES.EMAIL, fieldID: "email" },
  { fieldType: FIELD_TYPES.CHECKBOX, fieldID: "married" },
  { fieldType: FIELD_TYPES.NUMBER, fieldID: "number" },
  {
    fieldType: FIELD_TYPES.SINGLE_DROPDOWN,
    fieldID: "dropdown",
    fieldOptions: ["Option 1", "Option 2", "Option 3"]
  }
];

const GeneratedFormSchema = [
  {
    section: "Field Set Section",
    fieldSet: TestFields
  }
];

const PatientDemographicsFields = [
  {
    fieldType: FIELD_TYPES.TEXT_REQ,
    fieldID: "first_name",
    label: "First name"
  },
  { fieldType: FIELD_TYPES.TEXT_REQ, fieldID: "last_name", label: "Last name" },
  { fieldType: FIELD_TYPES.DATE_REQ, fieldID: "dob", label: "Date of birth" },
  {
    fieldType: FIELD_TYPES.SINGLE_DROPDOWN,
    fieldID: "sex",
    options: [
      {
        value: METRICS.MALE,
        label: FORMAT.capitalize(METRICS.MALE)
      },
      { value: METRICS.FEMALE, label: FORMAT.capitalize(METRICS.FEMALE) },
      { value: METRICS.UNKNOWN, label: FORMAT.capitalize(METRICS.UNKNOWN) }
    ],
    label: "Sex"
  },
  { fieldType: FIELD_TYPES.TEXT, fieldID: "pronouns", label: "Pronouns" },
  { fieldType: FIELD_TYPES.SSN, fieldID: "ssn", label: "SSN" },
  { fieldType: FIELD_TYPES.EMAIL, fieldID: "email", label: "Email" },
  { fieldType: FIELD_TYPES.PHONE, fieldID: "phone", label: "Phone number" },
  {
    fieldType: FIELD_TYPES.TEXT,
    fieldID: "addr_line_1",
    label: "Address line 1"
  },
  {
    fieldType: FIELD_TYPES.ANY,
    fieldID: "addr_line_2",
    label: "Address line 2"
  },
  {
    fieldType: FIELD_TYPES.ANY,
    fieldID: "addr_line_3",
    label: "Address line 3"
  },
  { fieldType: FIELD_TYPES.TEXT, fieldID: "city", label: "City" },
  {
    fieldType: FIELD_TYPES.SINGLE_DROPDOWN,
    fieldID: "state",
    options: STATE_CODES.map(code => ({ value: code.toLowerCase(), label: code })),
    label: "State"
  },
  { fieldType: FIELD_TYPES.ZIPCODE, fieldID: "zip", label: "Zipcode" }
];

const EmergencyContactFields = [
  { fieldType: FIELD_TYPES.TEXT_REQ, fieldID: "name" },
  { fieldType: FIELD_TYPES.EMAIL, fieldID: "email" },
  { fieldType: FIELD_TYPES.PHONE, fieldID: "phone" },
  {
    fieldType: FIELD_TYPES.SINGLE_DROPDOWN,
    fieldID: "phone_type",
    options: ["Home", "Work", "Mobile"]
  },
  { fieldType: FIELD_TYPES.TEXT, fieldID: "relationship" }
];

const newPatientDemographics = [
  { fieldType: FIELD_TYPES.TEXT_REQ, fieldID: "patient.first_name" },
  { fieldType: FIELD_TYPES.TEXT_REQ, fieldID: "patient.last_name" },
  { fieldType: FIELD_TYPES.DATE_REQ, fieldID: "patient.dob" },
  { fieldType: FIELD_TYPES.EMAIL, fieldID: "patient.email" },
  { fieldType: FIELD_TYPES.PHONE, fieldID: "patient.phone" },
  { fieldType: FIELD_TYPES.TEXT, fieldID: "patient.addr_line_1" },
  { fieldType: FIELD_TYPES.ANY, fieldID: "patient.addr_line_2" },
  { fieldType: FIELD_TYPES.ANY, fieldID: "patient.addr_line_3" },
  { fieldType: FIELD_TYPES.TEXT, fieldID: "patient.city" },
  { fieldType: FIELD_TYPES.STATE, fieldID: "patient.state" },
  { fieldType: FIELD_TYPES.ZIPCODE, fieldID: "patient.zip" }
];

const CreateAppointmentFields = [
  { fieldType: FIELD_TYPES.TEXT_REQ, fieldID: "appointment.type" },
  { fieldType: FIELD_TYPES.ANY, fieldID: "appointment.patient" },
  { fieldType: FIELD_TYPES.ANY, fieldID: "appointment.provider" },
  { fieldType: FIELD_TYPES.ANY, fieldID: "appointment.location" },
  { fieldType: FIELD_TYPES.DATE_REQ, fieldID: "appointment.date" },
  { fieldType: FIELD_TYPES.NUMBER, fieldID: "appointment.start" },
  { fieldType: FIELD_TYPES.NUMBER, fieldID: "appointment.end" },
  ...newPatientDemographics
];

const CreateProblemFields = [
  { fieldType: FIELD_TYPES.TEXT_REQ, fieldID: "problem_new_note" },
  { fieldType: FIELD_TYPES.TEXT, fieldID: "new_problem_title" },
  { fieldType: FIELD_TYPES.ANY, fieldID: "select_icd10" }
];

const InsuranceFormFields = [
  { fieldType: FIELD_TYPES.TEXT_REQ, fieldID: "plan_type" },
  { fieldType: FIELD_TYPES.TEXT_REQ, fieldID: "subscriber_first_name" },
  { fieldType: FIELD_TYPES.TEXT_REQ, fieldID: "subscriber_last_name" },
  { fieldType: FIELD_TYPES.TEXT_REQ, fieldID: "member_or_subscriber_id" },
  { fieldType: FIELD_TYPES.TEXT_REQ, fieldID: "policy_or_group_number" }
];

const NotifyPatientFields = [
  // TO DEFINE
  { fieldType: FIELD_TYPES.TEXT, fieldID: "email_name" }
];

const FORM_SCHEMA = {
  GeneratedFormSchema,
  CreateAppointmentFields,
  PatientDemographicsFields,
  EmergencyContactFields,
  NotifyPatientFields,
  CreateProblemFields,
  InsuranceFormFields
};

export { FIELD_VALIDATION, FIELD_TYPES, FORM_SCHEMA, buildZodObject };

export type { FORM_ITEMS };
