// External
import { useEffect, useMemo, useState } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import dayjs from "dayjs";
import Skeleton from "react-loading-skeleton";

// Components
import Checkbox from "@/components/input/checkbox";

// Store
import {
  EncounterInfo,
  FileListItem,
  LabOrderInfo,
  PatientGetLabOrdersApiResponse,
  PatientInfo,
  usePatientGetLabOrdersQuery
} from "@/store/services/patient";
import { useFileListQuery, FileList, file } from "@/store/services/file";
import {
  EncounterListApiResponse,
  useEncounterListQuery
} from "@/store/services/encounter";

// Styles
import styles from "../styles.module.scss";
import AllergyList from "@/components/allergyList";

export type AdditionalDataFormType = FieldValues & {
  demographics: boolean;
  insurance: boolean;
  health_history: boolean;
  problem_list: boolean;
  prenatal_flowsheet: boolean;
  encounters: boolean;
  prenatal_labs: boolean;
  allergy_list: boolean;
  medication_list: boolean;
  all_files: boolean;
  all_labs: boolean;
};

interface AdditionalDataCheckListProps<T extends AdditionalDataFormType> {
  patient: PatientInfo;
  form: UseFormReturn<T>;
  genericRecords?: boolean;
}

export default function AdditionalDataCheckList({
  patient,
  form,
  genericRecords = false
}: AdditionalDataCheckListProps<AdditionalDataFormType>) {
  const { data: labFiles, isLoading: isLabFilesLoading } = useFileListQuery(
    {
      scope: "patient",
      id: patient.user_id,
      tagName: "labs",
      pagesz: 1000
    },
    {
      skip: !patient.user_id
    }
  );

  const { data: imageFiles, isLoading: isImageFilesLoading } = useFileListQuery(
    {
      scope: "patient",
      id: patient.user_id,
      tagName: "imaging",
      pagesz: 1000
    },
    {
      skip: !patient.user_id
    }
  );

  const { data: fileData } = useFileListQuery(
    {
      scope: "patient",
      id: patient.user_id,
      // TODO: We need to rework the entire UX for this so pagination makes sense
      pagesz: 1000,
      tagNameExclude: ["labs", "imaging"]
    },
    {
      skip: !patient.user_id
    }
  );

  const { data: encounters, isLoading: isEncountersLoading } =
    useEncounterListQuery({
      scope: "patient",
      id: patient.user_id,
      pagesz: 100
    });

  const { register, watch, setValue } = form;

  /**
   * Sets all the checkbox inputs to the value passed in
   * @param listingObject The data we'll loop through to get all of the object IDs (add new listing type to the arg type if needed)
   * @param idPrefix The string prefix to the ID of the input in the form of ${idPrefix}${listingItem.id}
   * @param value The value to set all the inputs to
   */
  const setAllInputs = (
    listingObject:
      | FileList
      | PatientGetLabOrdersApiResponse
      | EncounterListApiResponse,
    idPrefix: string,
    value: boolean
  ) => {
    listingObject.forEach(
      (listingItem: FileListItem | LabOrderInfo | EncounterInfo) => {
        let id;
        if (listingItem.hasOwnProperty("file_id")) {
          id = (listingItem as FileListItem).file_id;
        } else if (listingItem.hasOwnProperty("order_id")) {
          id = (listingItem as LabOrderInfo).order_id;
        } else if (listingItem.hasOwnProperty("encounter_id")) {
          id = (listingItem as EncounterInfo).encounter_id;
        }
        setValue(`${idPrefix}${id}`, value);
      }
    );
  };

  useEffect(() => {
    // Value is the value of the whole form, name is the name of the input that changed
    const subscription = watch((value, { name }) => {
      // If what just changed was one of the "all"s then don't do anything here
      if (
        name === "all_encounters" ||
        name === "all_files" ||
        name === "all_labs" ||
        !name
      ) {
        return;
      }
      // If the key starts with one of our "all" keys and is false, set "all_*" to false
      if (name.startsWith("encounter-") && !value[name]) {
        setValue("all_encounters", false);
      }
      if (name.startsWith("file") && !value[name]) {
        setValue("all_files", false);
      }
      if (name.startsWith("lab") && !value[name]) {
        setValue("all_labs", false);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const additionalDataOptions = useMemo(() => {
    // PROBLEM LABEL
    const hasProblems = (patient.problems &&
      (patient.problems.diagnoses.length > 0 ||
        patient.problems.notes.length > 0)) as boolean;
    let problemLabel = "Problem List";
    if (!hasProblems) {
      if (patient.medical_history.no_known_problems) {
        problemLabel += " (NKP)";
      } else {
        problemLabel += " (Empty)";
      }
    }

    // ALLERGY LABEL
    const hasAllergies = (patient.medical_history.allergies &&
      patient.medical_history.allergies.length > 0) as boolean;
    let allergyLabel = "Allergy List";
    if (!hasAllergies) {
      if (patient.medical_history.no_known_drug_allergies) {
        allergyLabel += " (NKDA)";
      } else {
        allergyLabel += " (Empty)";
      }
    }

    // MEDICATION LABEL
    const hasCurrentMeds = (patient.medical_history.medications &&
      Object.entries(patient.medical_history.medications).length >
        0) as boolean;
    let medicationLabel = "Medications";
    if (!hasCurrentMeds) {
      if (patient.medical_history.no_known_medications) {
        medicationLabel += " (NKM)";
      } else {
        medicationLabel += " (Empty)";
      }
    }

    let additionalOptions = [
      {
        id: "demographics",
        label: "Demographics"
      },
      {
        id: "insurance",
        label: (!!patient.insurance as boolean)
          ? "Insurance"
          : "Insurance (Empty)"
      },
      {
        id: "problem_list",
        label: problemLabel
      },
      {
        id: "medication_list",
        label: medicationLabel
      },
      {
        id: "allergy_list",
        label: allergyLabel
      },
      {
        id: "health_history",
        label: "Health History"
      }
    ];

    if (
      patient.practice_data.type === "OB" ||
      patient.practice_data.type === "POSTPARTUM"
    ) {
      const hasEdd = !!patient.pregnancy?.edd as boolean;
      const hasFlowSheet = !!patient.pregnancy?.flow_sheet as boolean;
      const hasCurrentPregnancy = !!patient.pregnancy as boolean;

      const obOptions = [
        {
          id: "pregnancy_edd",
          label: hasEdd ? "Pregnancy EDD + LMP" : "Pregnancy EDD + LMP (Empty)"
        },
        {
          id: "prenatal_flowsheet",
          label: hasFlowSheet
            ? "Prenatal Flow Sheet"
            : "Prenatal Flow Sheet (Empty)"
        }
      ];

      if (hasCurrentPregnancy) {
        // Most of them we want to include even if the patient doesn't have data
        // but this seems incorrect to include if there's no current pregnancy
        obOptions.push({
          id: "current_pregnancy",
          label: "Current pregnancy"
        });
      }

      additionalOptions = [
        ...additionalOptions,
        ...obOptions,
      ]
    }

    if (patient.practice_data.type === "INFANT") {
      const infantOptions = [
        {
          id: "pediatric_record",
          label: "Pediatric Record"
        }
      ];

      additionalOptions = [
        ...additionalOptions,
        ...infantOptions
      ]
    }

    if (patient.sex === "FEMALE" && patient.practice_data.type !== "INFANT") {
      const hasPastPregnancies = patient.medical_history.pregnancies && patient.medical_history.pregnancies.length > 0;

      additionalOptions.push({
        id: "past_pregnancies",
        label: hasPastPregnancies ? "Past Pregnancies" : "Past Pregnancies (Empty)",
      })
    }

    if (patient.pregnancy?.labor) {
      const laborOptions = [
        {
          id: "labor_sheet",
          label: "Labor Sheet"
        },
        {
          id: "newborn_exam",
          label: "Newborn Exam"
        }
      ];

      additionalOptions = [
        ...additionalOptions,
        ...laborOptions
      ]
    }

    return additionalOptions;
  }, [patient, encounters]);


  const getEncounterLabel = (encounter: EncounterInfo) => {
    const apptType = encounter.appointment_type?.appointment_type;
    let encType = encounter.encounter_type?.replaceAll("_", " ");
    if (encType && encType !== "") {
      encType = encType.slice(0, 1).concat(encType.slice(1).toLowerCase());
    }
    const dateString = dayjs(
      `${
        encounter.start || encounter.appointment?.starts || encounter.created
      }`,
      "YYYYMMDDHHmmss"
    ).format("MM/DD/YYYY");
    return apptType
      ? `${apptType} - ${dateString}`
      : `${encType} - ${dateString}`;
  };

  // Only prenatal labs in a labor transfer not generic
  if (!genericRecords) {
    additionalDataOptions.push({
      id: "prenatal_labs",
      label: "Prenatal Labs"
    });
  }

  return (
    <div className={styles.additionalChecks}>
      {genericRecords && (
        <>
          <div className={styles.additionalData}>
            <div className={styles.fileListChecks}>
              <p>Additional data</p>
              <div className={styles.subCheckList}>
                {additionalDataOptions.map(option => (
                  <Checkbox
                    label={option.label}
                    id={option.id}
                    name={option.id}
                    register={register}
                    key={option.id}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className={styles.additionalData}>
            <div className={styles.fileListChecks}>
              <p>Encounters</p>
              {isEncountersLoading && (
                <>
                  <Skeleton height="20" width="80%" />
                  <Skeleton height="20" width="80%" />
                  <Skeleton height="20" width="80%" />
                  <Skeleton height="20" width="80%" />
                </>
              )}
              {encounters && encounters.length > 0 ? (
                <>
                  <Checkbox
                    label="All encounters"
                    id="all_encounters"
                    name="all_encounters"
                    register={register}
                    rules={{
                      onChange: (e: any) => {
                        setAllInputs(
                          encounters,
                          "encounter-",
                          e.target.checked
                        );
                      }
                    }}
                  />

                  <div className={styles.subCheckList}>
                    {encounters.map(encounter => (
                      <Checkbox
                        label={getEncounterLabel(encounter)}
                        id={`encounter-${encounter.encounter_id}`}
                        name={`encounter-${encounter.encounter_id}`}
                        register={register}
                        key={encounter.encounter_id}
                        defaultChecked={watch(
                          `encounter-${encounter.encounter_id}`
                        )}
                      />
                    ))}
                  </div>
                </>
              ) : (
                !isEncountersLoading && (
                  <p>This patient doesn't have any encounters.</p>
                )
              )}
            </div>
          </div>
        </>
      )}
      <div className={styles.additionalFiles}>
        <div className={styles.fileListChecks}>
          <p>Labs</p>
          {isLabFilesLoading && (
            <>
              <Skeleton height="20" width="80%" />
              <Skeleton height="20" width="80%" />
              <Skeleton height="20" width="80%" />
              <Skeleton height="20" width="80%" />
            </>
          )}
          {labFiles && labFiles.length > 0 ? (
            <>
              <Checkbox
                label="All labs"
                id="all_labs"
                name="all_labs"
                register={register}
                rules={{
                  onChange: () => {
                    setAllInputs(labFiles, "lab_", watch("all_labs"));
                  }
                }}
              />
              <div className={styles.subCheckList}>
                {labFiles.map(lab => (
                  <Checkbox
                    label={lab.name || ""}
                    register={register}
                    id={`lab_${lab.file_id}`}
                    name={`lab_${lab.file_id}`}
                    key={lab.file_id}
                  />
                ))}
              </div>
            </>
          ) : (
            !isLabFilesLoading && <p>Patient doesn't have any labs.</p>
          )}
        </div>
      </div>
      <div className={styles.additionalFiles}>
        <div className={styles.fileListChecks}>
          <p>Imaging</p>
          {isImageFilesLoading && (
            <>
              <Skeleton height="20" width="80%" />
              <Skeleton height="20" width="80%" />
              <Skeleton height="20" width="80%" />
              <Skeleton height="20" width="80%" />
            </>
          )}
          {imageFiles && imageFiles.length > 0 ? (
            <>
              <Checkbox
                label="All imaging"
                id="all_imaging"
                name="all_imaging"
                register={register}
                rules={{
                  onChange: () => {
                    setAllInputs(imageFiles, "imaging_", watch("all_imaging"));
                  }
                }}
              />
              <div className={styles.subCheckList}>
                {imageFiles.map(i => (
                  <Checkbox
                    label={i.name || ""}
                    register={register}
                    id={`imaging_${i.file_id}`}
                    name={`imaging_${i.file_id}`}
                    key={i.file_id}
                  />
                ))}
              </div>
            </>
          ) : (
            !isImageFilesLoading && (
              <p>Patient doesn't have any imaging files.</p>
            )
          )}
        </div>
      </div>
      <div className={styles.additionalFiles}>
        <div className={styles.fileListChecks}>
          <p>Additional files</p>
          {fileData && fileData.length > 0 && (
            <>
              <Checkbox
                label="All files"
                id="all_files"
                name="all_files"
                register={register}
                rules={{
                  onChange: () => {
                    setAllInputs(fileData, "file_", watch("all_files"));
                  }
                }}
              />
              <div className={styles.subCheckList}>
                {fileData.map(file => (
                  <Checkbox
                    label={file.name}
                    register={register}
                    id={`file_${file.file_id}`}
                    name={`file_${file.file_id}`}
                    key={file.file_id}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
