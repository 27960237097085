import { backendSlice as api } from "../../../components/api/apiSlice";
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    files: build.query<FilesApiResponse, FilesApiArg>({
      query: queryArg => ({
        url: `/files`,
        params: { file_ids: queryArg.fileIds }
      })
    }),
    fileList: build.query<FileListApiResponse, FileListApiArg>({
      query: queryArg => ({
        url: `/files/${queryArg.scope}/${queryArg.id}/list`,
        params: {
          file_name: queryArg.fileName,
          tag_name: queryArg.tagName,
          tag_name_exclude: queryArg.tagNameExclude,
          page: queryArg.page,
          pagesz: queryArg.pagesz
        }
      })
    }),
    fileUpload: build.mutation<FileUploadApiResponse, FileUploadApiArg>({
      query: queryArg => ({
        url: `/files/upload`,
        method: "POST",
        body: queryArg.body,
        params: {
          patient_id: queryArg.patientId,
          pregnancy_id: queryArg.pregnancyId,
          location_id: queryArg.locationId
        }
      })
    }),
    fileGenerateDefaultUrl: build.query<
      FileGenerateDefaultUrlApiResponse,
      FileGenerateDefaultUrlApiArg
    >({
      query: queryArg => ({ url: `/files/${queryArg.fileId}/url` })
    }),
    fileGenerateUrl: build.mutation<
      FileGenerateUrlApiResponse,
      FileGenerateUrlApiArg
    >({
      query: queryArg => ({
        url: `/files/${queryArg.fileId}/url`,
        method: "POST",
        body: queryArg.fileUrlRequest
      })
    }),
    fileDetails: build.query<FileDetailsApiResponse, FileDetailsApiArg>({
      query: queryArg => ({ url: `/files/${queryArg.fileId}/details` })
    }),
    fileUpdate: build.mutation<FileUpdateApiResponse, FileUpdateApiArg>({
      query: queryArg => ({
        url: `/files/${queryArg.fileId}/update`,
        method: "POST",
        body: queryArg.fileUpdateRequest
      })
    }),
    fileToggleReviewed: build.mutation<
      FileToggleReviewedApiResponse,
      FileToggleReviewedApiArg
    >({
      query: queryArg => ({
        url: `/files/${queryArg.fileId}/toggle-reviewed`,
        method: "POST"
      })
    }),
    fileDelete: build.mutation<FileDeleteApiResponse, FileDeleteApiArg>({
      query: queryArg => ({
        url: `/files/delete`,
        method: "POST",
        body: queryArg.fileDeletePurgeRequest
      })
    }),
    filePurge: build.mutation<FilePurgeApiResponse, FilePurgeApiArg>({
      query: queryArg => ({
        url: `/files/purge`,
        method: "POST",
        body: queryArg.fileDeletePurgeRequest
      })
    }),
    fileBatchUpdate: build.mutation<
      FileBatchUpdateApiResponse,
      FileBatchUpdateApiArg
    >({
      query: queryArg => ({
        url: `/files/batch-update`,
        method: "POST",
        body: queryArg.fileBatchUpdateRequest
      })
    }),
    fileBatchCopy: build.mutation<
      FileBatchCopyApiResponse,
      FileBatchCopyApiArg
    >({
      query: queryArg => ({
        url: `/files/batch-copy`,
        method: "POST",
        body: queryArg.fileBatchCopyRequest
      })
    }),
    fileAnnotationCreate: build.mutation<
      FileAnnotationCreateApiResponse,
      FileAnnotationCreateApiArg
    >({
      query: queryArg => ({
        url: `/files/${queryArg.fileId}/annotation/create`,
        method: "POST",
        body: queryArg.fileAnnotationCreateRequest
      })
    }),
    fileAnnotationUpdate: build.mutation<
      FileAnnotationUpdateApiResponse,
      FileAnnotationUpdateApiArg
    >({
      query: queryArg => ({
        url: `/files/${queryArg.fileId}/annotation/${queryArg.annotationId}/update`,
        method: "POST",
        body: queryArg.fileAnnotationUpdateRequest
      })
    }),
    fileAnnotationDelete: build.mutation<
      FileAnnotationDeleteApiResponse,
      FileAnnotationDeleteApiArg
    >({
      query: queryArg => ({
        url: `/files/${queryArg.fileId}/annotation/${queryArg.annotationId}/delete`,
        method: "POST"
      })
    })
  }),
  overrideExisting: true
});
export { injectedRtkApi as file };
export type FilesApiResponse = /** status 200 List of requested file types.
 */ FileList;
export type FilesApiArg = {
  /** A list of file IDs to retrieve.
   */
  fileIds?: FileId[];
};
export type FileListApiResponse = /** status 200 List of requested file types.
 */ FileList;
export type FileListApiArg = {
  /** The scope of files to list. Must be one of: practice, location, provider, or user.
   */
  scope: FileScope;
  /** This is used to filter returned files to include only those containing
    the provided string as a substring of the file's name.
     */
  fileName?: string;
  /** This is used to filter returned files to include only those tagged with
    the provided tag.
     */
  tagName?: TagName;
  /** This is used to filter returned files to exclude those tagged with
    the provided tag.
     */
  tagNameExclude?: TagName[];
  id: Id;
  page?: Page;
  pagesz?: PageSize;
};
export type FileUploadApiResponse =
  /** status 200 This is sent in reply to a successful file create request.
   */ FileCreateReply;
export type FileUploadApiArg = {
  patientId?: UserId;
  pregnancyId?: PregnancyId;
  locationId?: LocationId;
  /** Payload required when a file is created. This provides the basic metadata stored for the file and makes it
    possible to upload the file contents to S3.
     */
  body: FormData;
};
export type FileGenerateDefaultUrlApiResponse =
  /** status 200 Temporary S3 retrieval URL.
   */ string;
export type FileGenerateDefaultUrlApiArg = {
  /** The ID of a previously created file.
   */
  fileId: FileId;
};
export type FileGenerateUrlApiResponse =
  /** status 200 Temporary S3 retrieval URL.
   */ string;
export type FileGenerateUrlApiArg = {
  /** The ID of a previously created file.
   */
  fileId: FileId;
  /** Payload for creating an S3 download URL using custom parameters. This is forward-looking and the backend doesn't
    actually implement this yet.
     */
  fileUrlRequest: FileUrlRequest;
};
export type FileDetailsApiResponse = /** status 200 Temporary S3 retrieval URL.
 */ FileDetails;
export type FileDetailsApiArg = {
  /** The ID of a previously created file.
   */
  fileId: FileId;
};
export type FileUpdateApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type FileUpdateApiArg = {
  /** The ID of a previously created file.
   */
  fileId: FileId;
  /** Payload to update a file.
   */
  fileUpdateRequest: FileUpdateRequest;
};
export type FileToggleReviewedApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type FileToggleReviewedApiArg = {
  /** The ID of a previously created file.
   */
  fileId: FileId;
};
export type FileDeleteApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type FileDeleteApiArg = {
  /** Payload required for deleting one or more files. This is an array of file numbers. Absolutely no confirmation
    is requested so be very careful.
     */
  fileDeletePurgeRequest: FileDeletePurgeRequest;
};
export type FilePurgeApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type FilePurgeApiArg = {
  /** Payload required for deleting one or more files. This is an array of file numbers. Absolutely no confirmation
    is requested so be very careful.
     */
  fileDeletePurgeRequest: FileDeletePurgeRequest;
};
export type FileBatchUpdateApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type FileBatchUpdateApiArg = {
  /** Payload to update a batch of files.
   */
  fileBatchUpdateRequest: FileBatchUpdateRequest;
};
export type FileBatchCopyApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type FileBatchCopyApiArg = {
  /** Payload to copy a batch of files.
   */
  fileBatchCopyRequest: FileBatchCopyRequest;
};
export type FileAnnotationCreateApiResponse = /** status 200 Annotation id
 */ AnnotationId;
export type FileAnnotationCreateApiArg = {
  /** The ID of a previously created file.
   */
  fileId: FileId;
  /** Payload to create an annotation on a file.
   */
  fileAnnotationCreateRequest: FileAnnotationCreateRequest;
};
export type FileAnnotationUpdateApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type FileAnnotationUpdateApiArg = {
  /** The ID of a previously created file.
   */
  fileId: FileId;
  /** The ID of a previously created annotation.
   */
  annotationId: AnnotationId;
  /** Payload to update an annotation on a file.
   */
  fileAnnotationUpdateRequest: FileAnnotationUpdateRequest;
};
export type FileAnnotationDeleteApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type FileAnnotationDeleteApiArg = {
  /** The ID of a previously created file.
   */
  fileId: FileId;
  /** The ID of a previously created annotation.
   */
  annotationId: AnnotationId;
};
export type FileUploader = {
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  user_id?: number;
  /** A user's first name. This only applies to humans, not practice or location names.
   */
  first_name?: string;
  /** A user's last name. This only applies to humans, not practice or location names.
   */
  last_name?: string;
};
export type Sex = "MALE" | "FEMALE" | "UNKNOWN";
export type Statecode =
  | "AK"
  | "AL"
  | "AR"
  | "AZ"
  | "CA"
  | "CO"
  | "CT"
  | "DC"
  | "DE"
  | "FL"
  | "GA"
  | "HI"
  | "IA"
  | "ID"
  | "IL"
  | "IN"
  | "KS"
  | "KY"
  | "LA"
  | "MA"
  | "MD"
  | "ME"
  | "MI"
  | "MN"
  | "MO"
  | "MS"
  | "MT"
  | "NC"
  | "ND"
  | "NE"
  | "NH"
  | "NJ"
  | "NM"
  | "NV"
  | "NY"
  | "OH"
  | "OK"
  | "OR"
  | "PA"
  | "PR"
  | "RI"
  | "SC"
  | "SD"
  | "TN"
  | "TX"
  | "UT"
  | "VA"
  | "VI"
  | "VT"
  | "WA"
  | "WI"
  | "WV"
  | "WY";
export type RoleId = number;
export type UserPracticeRoles = RoleId[];
export type LocationId = number;
export type LocationIds = LocationId[];
export type UserMetaPractice = {
  roles: UserPracticeRoles;
  locations: LocationIds;
  /** A yes/no or true/false value.
   */
  is_ma: boolean;
  /** A yes/no or true/false value.
   */
  is_provider: boolean;
  /** A yes/no or true/false value.
   */
  is_admin: boolean;
  /** A yes/no or true/false value.
   */
  is_superadmin: boolean;
  /** A yes/no or true/false value.
   */
  is_patient: boolean;
  /** A yes/no or true/false value.
   */
  is_biller: boolean;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  last_email?: number;
};
export type UserMetaPractices = {
  [key: string]: UserMetaPractice;
};
export type UserMetaLast = {
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  /** A role which can be assigned to a user at the practice. The role ID is defined at the practice level but
    a given user can have a different role at each location. This is used to determine what the user can do at the
    location level. For example, a user may be a patient at one location and a front desk person at another. Each
    user in a practice has a practice role too, which is used to determine what they can do at the practice level.
    So a user will have a practice role and a location role (or multiple location roles).
     */
  role_id: number;
};
export type UserDosespotMeta = {
  patient_id?: number;
  proxy_clinician_id?: number;
  prescribing_clinician_id?: number;
};
export type UserMetadata = {
  practices: UserMetaPractices;
  /** National Provider Identifier (NPI).
   */
  npi?: string;
  /** Drug Enforcement Administration (DEA) number.
   */
  dea_id?: string;
  last?: UserMetaLast;
  dosespot: UserDosespotMeta;
};
export type BloodType =
  | "A+"
  | "A-"
  | "B+"
  | "B-"
  | "AB+"
  | "AB-"
  | "O+"
  | "O-"
  | "UNKNOWN";
export type Diabetes = "TYPE_1" | "TYPE_2" | "GESTATIONAL" | "NONE";
export type HivStatus = "POSITIVE" | "NEGATIVE" | "UNKNOWN";
export type Icd10CodeDetails = {
  /** The system-generated ID for the ICD-10 code. We always use this ID and not the ICD10 code itself, as the codes
    change year by year and we need to make sure we preserve the code across such changes. Thus these codes are
    versioned, and this is how we reference a code at a particular version.
     */
  icd_id: number;
  /** The actual ICD-10 code as provided by the WHO (no, not the rock band).
   */
  name: string;
  /** The human-readable description of the code.
   */
  description: string;
};
export type AllergySeverity = "MILD" | "MODERATE" | "SEVERE" | "FATAL";
export type Allergy = {
  icd10_code_details?: Icd10CodeDetails;
  name?: string;
  reaction?: string;
  severity?: AllergySeverity;
  notes?: string;
  date_of_onset?: string;
  allergy_id?: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  created_at?: string;
};
export type Allergies = Allergy[];
export type Immunization = {
  name: string;
  /** The system-generated ID for the CPT code. We always use this ID and not the CPT code itself, as the codes
    change year by year and we need to make sure we preserve the code across such changes. Thus these codes are
    versioned, and this is how we reference a code at a particular version.
     */
  cpt: number;
  /** A Pario date in YYYYMMDD format.
   */
  date?: number;
  notes?: string;
};
export type Immunizations = Immunization[];
export type MedicationStatus =
  | "ACTIVE"
  | "INACTIVE"
  | "NONCOMPLIANT"
  | "DELETED";
export type Medication = {
  name: string;
  dosage?: string;
  comment?: string;
  status: MedicationStatus;
  /** A Pario date in YYYYMMDD format.
   */
  start?: number;
  /** A Pario date in YYYYMMDD format.
   */
  end?: number;
  lexicomp_product_id?: number;
  lexicomp_drug_id?: string;
  lexicomp_synonym_id?: number;
  lexicomp_synonym_type?: number;
  dosespot_id?: number;
  days_supply?: number;
  is_prescription?: boolean;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  created_at?: string;
};
export type Medications = {
  [key: string]: Medication;
};
export type EddMethod = "LMP" | "ULTRASOUND" | "DATE_OF_CONCEPTION" | "UNKNOWN";
export type EstimatedDueDate = {
  method: EddMethod;
  estimated_due_date?: string;
  date_of_service?: string;
  date_of_method?: string;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  provider_id?: number;
  estimate_id?: string;
  comment?: string;
  lmp_cycle_length_days?: number;
  file_id?: number;
};
export type Lmp = {
  last_menstrual_period?: string;
  estimate_id?: string;
};
export type PregnancyState =
  | "GESTATING"
  | "DELIVERED"
  | "MISCARRIED"
  | "STILLBORN"
  | "ECTOPIC"
  | "TERMINATED"
  | "POSTPARTUM"
  | "UNSPECIFIED"
  | "DELETED";
export type PrenatalFlowSheetEntry = {
  weeks?: string;
  days?: string;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  encounter_start?: number;
  weight?: number;
  blood_pressure?: string;
  temperature?: number;
  fundus?: string;
  fundal_height?: string;
  fetal_heart_tones?: string;
  fetal_position?: string;
  fetal_activity?: string;
  edema?: string;
  pulse?: number;
  note?: string;
  /** System-assigned ID of an encounter, unique for a patient. Every time a patient visits a location for service,
    a new encounter is created in which to store the results and purpose of the encounter.
     */
  encounter_id?: number;
};
export type PrenatalFlowSheet = PrenatalFlowSheetEntry[];
export type GbsStatus = "POSITIVE" | "NEGATIVE" | "DECLINED";
export type LabOrderId = string;
export type LabOrders = LabOrderId[];
export type UserId = number;
export type UserIdList = UserId[];
export type LaborEvent = {
  event_id: string;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  start?: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  end?: number;
  authors?: UserIdList;
  forms?: object;
};
export type LaborStage = {
  events: {
    [key: string]: LaborEvent;
  };
  progress_updates: LaborEvent[];
  medications: LaborEvent[];
  infant_progress_updates?: LaborEvent[];
  infant_medications?: LaborEvent[];
  recurring_events?: LaborEvent[];
  notes: LaborEvent[];
};
export type LaborModel = {
  /** System-assigned sequence number for a unique pregnancy, regardless of state or outcome.
   */
  pregnancy_id: number;
  transfer?: object;
  stages: {
    [key: string]: LaborStage;
  };
  note?: string;
};
export type PregnancyOutcome =
  | "UNKNOWN"
  | "TAB (therapeautic abortion)"
  | "EAB (elective abortion)"
  | "SAB (miscarriage)"
  | "SBVD (stillbirth vaginal delivery)"
  | "SBCS (stillbirth cesarean delivery)"
  | "NSVD (vaginal delivery)"
  | "NSVB (vaginal birth)"
  | "VAVD (vacuum vaginal delivery)"
  | "FAVD (forceps vaginal delivery)"
  | "CS (cesarean)"
  | "VBAC (vaginal birth after previous cesarean)"
  | "NSVD (vaginal delivery) - Water"
  | "VBAC (vaginal birth after previous cesarean) - Water"
  | "EP (ectopic pregnancy)";
export type MaternityNeighborhoodPregnancyMetadata = {
  episode_of_care_id?: string;
  past_pregnancy_id?: string;
};
export type ClientCarePregnancyMetadata = {
  pregnancy_id?: string;
};
export type PregnancyMetadata = {
  maternity_neighborhood?: MaternityNeighborhoodPregnancyMetadata;
  client_care?: ClientCarePregnancyMetadata;
};
export type Pregnancy = {
  /** System-assigned sequence number for a unique pregnancy, regardless of state or outcome.
   */
  pregnancy_id: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  patient_id: number;
  edd?: EstimatedDueDate;
  lmp?: Lmp;
  state: PregnancyState;
  flow_sheet: PrenatalFlowSheet;
  /** Where the patient would like to have labor.
   */
  desired_birthplace?: string;
  gbs_status?: GbsStatus;
  lab_orders?: LabOrders;
  labor?: LaborModel;
  /** A Pario date in YYYYMMDD format.
   */
  delivery_date?: number;
  /** The gestational age of the baby at the time of delivery, measured
    in days.
     */
  gestational_age_at_delivery?: number;
  /** The length of labor in minutes.
   */
  length_of_labor_minutes?: number;
  /** The weight of an infant, in grams. This differs from the mothers weight which is in kilograms. The actual
    visual display may be converted to ounces, or kilograms with a fractional part, but this is how it is stored.
     */
  weight?: number;
  sex?: Sex;
  name?: string;
  living?: boolean;
  outcome?: PregnancyOutcome;
  /** Where the pregnancy was concluded.
   */
  birthplace?: string;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  infant_id?: number;
  comment?: string;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  created: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  updated: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  pregnancy_completion_time?: number;
  metadata: PregnancyMetadata;
};
export type Pregnancies = Pregnancy[];
export type AntibodyScreenDat = "NEGATIVE" | "POSITIVE" | "DECLINED";
export type Met1 = "NORMAL" | "ABNORMAL" | "DECLINED";
export type Met2 = "NORMAL" | "ABNORMAL" | "DECLINED";
export type UserMedicalHistory = {
  blood_type?: BloodType;
  /** Height of a person in CM. This is always stored as rounded-up centimeters although the visual presentation may
    be in feet and inches, or centimeters with a fractional part, but this is how it is stored.
     */
  height?: number;
  /** Weight of a non-infant. This is stored as kilograms.
   */
  weight?: number;
  diabetic?: Diabetes;
  hiv_status?: HivStatus;
  allergies?: Allergies;
  immunizations?: Immunizations;
  medications?: Medications;
  pregnancies?: Pregnancies;
  no_known_drug_allergies?: boolean;
  no_known_medications?: boolean;
  no_known_problems?: boolean;
  antibody_screen_DAT?: AntibodyScreenDat;
  met_1?: Met1;
  met_2?: Met2;
};
export type UserInfo = {
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  user_id: number;
  /** An email address. The front-end should attempt to remove any of the '+data' style email addresses.
    For example convert `john+pario@doe.com` to `john@doe.com` in order for de-duping to make more
    sense. This is not enforced by the backend, as we may use that for testing, but the front-end logic
    that creates an account should remove this.
     */
  email?: string;
  /** A user's first name. This only applies to humans, not practice or location names.
   */
  first_name: string;
  /** A user's last name. This only applies to humans, not practice or location names.
   */
  last_name: string;
  /** Optional name title (Dr., Sir, The Hon., Rev., Lady etc).
   */
  title?: string | null;
  /** Optional name suffix (Jr., III etc).
   */
  suffix?: string | null;
  /** A Pario date in YYYYMMDD format.
   */
  dob: number;
  sex: Sex;
  /** This is how the person identifies themselves and not necessarily medically accurate. See `sex`.
   */
  pronouns?: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  mobile?: string;
  /** Address line 1. Mandatory. This can include the street number.
   */
  addr_line_1?: string;
  /** Optional line 2 of 3 for an address.
   */
  addr_line_2?: string;
  /** Optional line 3 of 3 for an address.
   */
  addr_line_3?: string;
  /** Name of the city. Must be provided.
   */
  city?: string;
  state?: Statecode;
  /** Standard US / Canadian zip code, either in 5 digit or 5-4 digit form.
   */
  zip?: string;
  metadata: UserMetadata;
  medical_history: UserMedicalHistory;
};
export type FileListItem = {
  /** Unique file ID to reference a file stored in S3.
   */
  file_id: number;
  /** Name of a file (max 128 characters).
   */
  name: string;
  /** In a number of places (most notably files) we allow the attachment of an arbitrary list of tags.
    Most tags are simple booleans but a few can have more complex information. A location and a practice
    can define the valid list of tags. Because this is a dictionary it guarantees unique tags.
    This is the type for a collection of tags (i.e one of these will be attached to each practice or location).
     */
  tags: object;
  size: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  created: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  patient_id?: number;
  viewable_by_patient?: boolean;
  url: string;
  uploaded_by: FileUploader;
  has_annotations?: boolean;
  reviewed_by?: UserInfo;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  reviewed_at?: string;
};
export type FileList = FileListItem[];
export type ExtraErrorItem = {
  /** The name of the field that has an error.
   */
  field: string;
  /** Description of the error in the associated field.
   */
  message: string;
};
export type ErrorInfo = {
  /** Internal error ID. There is a defined list of error IDs, which may also appear in the database but should
    probably be implemented as a switch/case. This `error_id` is generated by the backed in response to various
    error conditions. 0 always means no error.
     */
  error: number;
  message: string;
  extra_data?: ExtraErrorItem[];
  /** If true, the error message is safe to display to the user. If false, this error may contain internal / technical information.
   */
  user_facing: boolean;
} | null;
export type FileId = number;
export type FileScope = "practice" | "location" | "patient" | "pregnancy";
export type TagName = string;
export type Id = number;
export type Page = number;
export type PageSize = number;
export type FileCreateReply = {
  [key: string]: FileId;
};
export type PregnancyId = number;
export type FileUrlRequest = object;
export type FileStatus = 0 | 1 | 2 | 3;
export type FileAnnotation = {
  /** System-assigned unique ID of an annotation. Used as a table linkage.
   */
  annotation_id: number;
  /** Unique file ID to reference a file stored in S3.
   */
  file_id: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  author: number;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  created: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  updated?: string;
  annotation: string;
};
export type FileDetails = {
  /** Unique file ID to reference a file stored in S3.
   */
  file_id: number;
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  /** Name of a file (max 128 characters).
   */
  name: string;
  size: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  created: number;
  /** System-assigned unique location identifier.
   */
  location_id?: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  patient_id?: number;
  viewable_by_patient?: boolean;
  /** System-assigned sequence number for a unique pregnancy, regardless of state or outcome.
   */
  pregnancy_id?: number;
  /** Number of times the file has been downloaded.
   */
  downloads: number;
  status: FileStatus;
  /** S3 ARN allocated to the file.
   */
  s3arn?: string;
  /** In a number of places (most notably files) we allow the attachment of an arbitrary list of tags.
    Most tags are simple booleans but a few can have more complex information. A location and a practice
    can define the valid list of tags. Because this is a dictionary it guarantees unique tags.
    This is the type for a collection of tags (i.e one of these will be attached to each practice or location).
     */
  tags: object;
  metadata: object;
  url: string;
  uploaded_by: FileUploader;
  annotations: FileAnnotation[];
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  reviewed_by?: number;
};
export type ErrorId = number;
export type TagId = number;
export type FileUpdateRequest = {
  /** Name of a file (max 128 characters).
   */
  name: string;
  tags: TagId[];
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  patient_id?: number;
  viewable_by_patient?: boolean;
  /** System-assigned sequence number for a unique pregnancy, regardless of state or outcome.
   */
  pregnancy_id?: number;
  date_of_service?: string;
};
export type FileDeletePurgeRequest = FileId[];
export type FileBatchUpdateRequest = {
  file_ids: FileId[];
  tag_names?: string[];
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  patient_id?: number;
  /** System-assigned sequence number for a unique pregnancy, regardless of state or outcome.
   */
  pregnancy_id?: number;
  viewable_by_patient?: boolean;
  date_of_service?: string;
};
export type FileBatchCopyRequest = {
  file_ids: FileId[];
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  patient_id: number;
};
export type AnnotationId = number;
export type FileAnnotationCreateRequest = {
  /** Unique file ID to reference a file stored in S3.
   */
  file_id: number;
  annotation: string;
};
export type FileAnnotationUpdateRequest = {
  /** System-assigned unique ID of an annotation. Used as a table linkage.
   */
  annotation_id: number;
  annotation: string;
};
export const {
  useFilesQuery,
  useLazyFilesQuery,
  useFileListQuery,
  useLazyFileListQuery,
  useFileUploadMutation,
  useFileGenerateDefaultUrlQuery,
  useLazyFileGenerateDefaultUrlQuery,
  useFileGenerateUrlMutation,
  useFileDetailsQuery,
  useLazyFileDetailsQuery,
  useFileUpdateMutation,
  useFileToggleReviewedMutation,
  useFileDeleteMutation,
  useFilePurgeMutation,
  useFileBatchUpdateMutation,
  useFileBatchCopyMutation,
  useFileAnnotationCreateMutation,
  useFileAnnotationUpdateMutation,
  useFileAnnotationDeleteMutation
} = injectedRtkApi;
